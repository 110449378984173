import React from "react"
import { Col, Container, Row } from "react-awesome-styled-grid"
import { Heading, Heading2 } from "../components/Heading"
import DivHelper from "../components/DivHelper"
import Layout from "../components/Layout"
import Block from "../components/Block"

const Datenschutz = () => {
  return (
    <Layout title="Datenschutz">
      <Container>
        <Row>
          <Col>
            <DivHelper center>
              <Heading>Datenschutzerklärung</Heading>
            </DivHelper>
          </Col>
        </Row>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m14">Einleitung</Heading2>
              <p>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
                aufklären, welche Arten Ihrer personenbezogenen Daten
                (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen
                Zwecken und in welchem Umfang verarbeiten. Die
                Datenschutzerklärung gilt für alle von uns durchgeführten
                Verarbeitungen personenbezogener Daten, sowohl im Rahmen der
                Erbringung unserer Leistungen als auch insbesondere auf unseren
                Webseiten, in mobilen Applikationen sowie innerhalb externer
                Onlinepräsenzen, wie z.B. unserer Social-Media-Profile
                (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
              </p>
              <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
              <p>Stand: 24. Januar 2022</p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Inhaltsübersicht</Heading2>
              <p>
                <ul class="index">
                  <li>
                    <a class="index-link" href="#m14">
                      Einleitung
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m3">
                      Verantwortlicher
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#mOverview">
                      Übersicht der Verarbeitungen
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m13">
                      Maßgebliche Rechtsgrundlagen
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m27">
                      Sicherheitsmaßnahmen
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m25">
                      Übermittlung von personenbezogenen Daten
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m24">
                      Datenverarbeitung in Drittländern
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m12">
                      Löschung von Daten
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m134">
                      Einsatz von Cookies
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m225">
                      Bereitstellung des Onlineangebotes und Webhosting
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m104">
                      Blogs und Publikationsmedien
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m182">
                      Kontakt- und Anfragenverwaltung
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m263">
                      Webanalyse, Monitoring und Optimierung
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m264">
                      Onlinemarketing
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m135">
                      Affiliate-Programme und Affiliate-Links
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m328">
                      Plugins und eingebettete Funktionen sowie Inhalte
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m15">
                      Änderung und Aktualisierung der Datenschutzerklärung
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m10">
                      Rechte der betroffenen Personen
                    </a>
                  </li>
                  <li>
                    <a class="index-link" href="#m42">
                      Begriffsdefinitionen
                    </a>
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m3">Verantwortlicher</Heading2>
              <p>
                A. Seipel
                <br />
                Weserstraße 41a
                <br />
                10247 Berlin
              </p>
              <p>
                E-Mail-Adresse:
                <br />
                seipel@cbd-vergleiche.de
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="mOverview">Übersicht der Verarbeitungen</Heading2>
              <p>
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                Daten und die Zwecke ihrer Verarbeitung zusammen und verweist
                auf die betroffenen Personen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Arten der verarbeiteten Daten</Heading2>
              <p>
                <ul>
                  <li>Bestandsdaten.</li>
                  <li>Kontaktdaten.</li>
                  <li>Inhaltsdaten.</li>
                  <li>Vertragsdaten.</li>
                  <li>Nutzungsdaten.</li>
                  <li>Meta-/Kommunikationsdaten.</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Kategorien betroffener Personen</Heading2>
              <p>
                <ul>
                  <li>Kommunikationspartner.</li>
                  <li>Nutzer.</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Zwecke der Verarbeitung</Heading2>
              <p>
                <ul>
                  <li>
                    Erbringung vertraglicher Leistungen und Kundenservice.
                  </li>
                  <li>Kontaktanfragen und Kommunikation.</li>
                  <li>Sicherheitsmaßnahmen.</li>
                  <li>Reichweitenmessung.</li>
                  <li>Remarketing.</li>
                  <li>Konversionsmessung.</li>
                  <li>Affiliate-Nachverfolgung.</li>
                  <li>Verwaltung und Beantwortung von Anfragen.</li>
                  <li>Feedback.</li>
                  <li>Marketing.</li>
                  <li>Profile mit nutzerbezogenen Informationen.</li>
                  <li>Zielgruppenbildung.</li>
                  <li>
                    Bereitstellung unseres Onlineangebotes und
                    Nutzerfreundlichkeit.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m13">Maßgebliche Rechtsgrundlagen</Heading2>
              <p>
                Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen
                der DSGVO, auf deren Basis wir personenbezogene Daten
                verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den
                Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw.
                unserem Wohn- oder Sitzland gelten können. Sollten ferner im
                Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen
                wir Ihnen diese in der Datenschutzerklärung mit.
              </p>
              <p>
                <ul>
                  <li>
                    <strong>
                      Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)
                    </strong>{" "}
                    - Die betroffene Person hat ihre Einwilligung in die
                    Verarbeitung der sie betreffenden personenbezogenen Daten
                    für einen spezifischen Zweck oder mehrere bestimmte Zwecke
                    gegeben.
                  </li>
                  <li>
                    <strong>
                      Vertragserfüllung und vorvertragliche Anfragen (Art. 6
                      Abs. 1 S. 1 lit. b. DSGVO)
                    </strong>{" "}
                    - Die Verarbeitung ist für die Erfüllung eines Vertrags,
                    dessen Vertragspartei die betroffene Person ist, oder zur
                    Durchführung vorvertraglicher Maßnahmen erforderlich, die
                    auf Anfrage der betroffenen Person erfolgen.
                  </li>
                  <li>
                    <strong>
                      Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c.
                      DSGVO)
                    </strong>{" "}
                    - Die Verarbeitung ist zur Erfüllung einer rechtlichen
                    Verpflichtung erforderlich, der der Verantwortliche
                    unterliegt.
                  </li>
                  <li>
                    <strong>
                      Schutz lebenswichtiger Interessen (Art. 6 Abs. 1 S. 1 lit.
                      d. DSGVO)
                    </strong>{" "}
                    - Die Verarbeitung ist erforderlich, um lebenswichtige
                    Interessen der betroffenen Person oder einer anderen
                    natürlichen Person zu schützen.
                  </li>
                  <li>
                    <strong>
                      Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
                    </strong>{" "}
                    - Die Verarbeitung ist zur Wahrung der berechtigten
                    Interessen des Verantwortlichen oder eines Dritten
                    erforderlich, sofern nicht die Interessen oder Grundrechte
                    und Grundfreiheiten der betroffenen Person, die den Schutz
                    personenbezogener Daten erfordern, überwiegen.
                  </li>
                </ul>
              </p>
              <p>
                Zusätzlich zu den Datenschutzregelungen der
                Datenschutz-Grundverordnung gelten nationale Regelungen zum
                Datenschutz in Deutschland. Hierzu gehört insbesondere das
                Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
                Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
                enthält insbesondere Spezialregelungen zum Recht auf Auskunft,
                zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung
                besonderer Kategorien personenbezogener Daten, zur Verarbeitung
                für andere Zwecke und zur Übermittlung sowie automatisierten
                Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
                Weiteren regelt es die Datenverarbeitung für Zwecke des
                Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
                Hinblick auf die Begründung, Durchführung oder Beendigung von
                Beschäftigungsverhältnissen sowie die Einwilligung von
                Beschäftigten. Ferner können Landesdatenschutzgesetze der
                einzelnen Bundesländer zur Anwendung gelangen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m27">Sicherheitsmaßnahmen</Heading2>
              <p>
                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                Berücksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umstände
                und der Zwecke der Verarbeitung sowie der unterschiedlichen
                Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
                Rechte und Freiheiten natürlicher Personen geeignete technische
                und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                Schutzniveau zu gewährleisten.
              </p>
              <p>
                Zu den Maßnahmen gehören insbesondere die Sicherung der
                Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
                Kontrolle des physischen und elektronischen Zugangs zu den Daten
                als auch des sie betreffenden Zugriffs, der Eingabe, der
                Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
                Des Weiteren haben wir Verfahren eingerichtet, die eine
                Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
                Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
                berücksichtigen wir den Schutz personenbezogener Daten bereits
                bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
                Verfahren entsprechend dem Prinzip des Datenschutzes, durch
                Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen.
              </p>
              <p>
                SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
                übermittelten Daten zu schützen, nutzen wir eine
                SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
                Verbindungen an dem Präfix https:// in der Adresszeile Ihres
                Browsers.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m25">
                Übermittlung von personenbezogenen Daten
              </Heading2>
              <p>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt
                es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
                selbstständige Organisationseinheiten oder Personen übermittelt
                oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern
                dieser Daten können z.B. mit IT-Aufgaben beauftragte
                Dienstleister oder Anbieter von Diensten und Inhalten, die in
                eine Webseite eingebunden werden, gehören. In solchen Fall
                beachten wir die gesetzlichen Vorgaben und schließen
                insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
                Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m24">Datenverarbeitung in Drittländern</Heading2>
              <p>
                Sofern wir Daten in einem Drittland (d.h., außerhalb der
                Europäischen Union (EU), des Europäischen Wirtschaftsraums
                (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
                Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
                Übermittlung von Daten an andere Personen, Stellen oder
                Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
                gesetzlichen Vorgaben.{" "}
              </p>
              <p>
                Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
                gesetzlich erforderlicher Übermittlung verarbeiten oder lassen
                wir die Daten nur in Drittländern mit einem anerkannten
                Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte
                Standardschutzklauseln der EU-Kommission, beim Vorliegen von
                Zertifizierungen oder verbindlicher internen
                Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,
                Informationsseite der EU-Kommission:{" "}
                <a
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
                </a>
                ).
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m12">Löschung von Daten</Heading2>
              <p>
                Die von uns verarbeiteten Daten werden nach Maßgabe der
                gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung
                erlaubten Einwilligungen widerrufen werden oder sonstige
                Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung
                dieser Daten entfallen ist oder sie für den Zweck nicht
                erforderlich sind).
              </p>
              <p>
                Sofern die Daten nicht gelöscht werden, weil sie für andere und
                gesetzlich zulässige Zwecke erforderlich sind, wird deren
                Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
                gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B.
                für Daten, die aus handels- oder steuerrechtlichen Gründen
                aufbewahrt werden müssen oder deren Speicherung zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen Person erforderlich ist.
              </p>
              <p>
                Unsere Datenschutzhinweise können ferner weitere Angaben zu der
                Aufbewahrung und Löschung von Daten beinhalten, die für die
                jeweiligen Verarbeitungen vorrangig gelten.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m134">Einsatz von Cookies</Heading2>
              <p>
                Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke,
                die Informationen auf Endgeräten speichern und Informationen aus
                den Endgeräten auslesen. Z.B. um den Login-Status in einem
                Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die
                aufgerufenen Inhalte oder verwendete Funktionen eines
                Onlineangebotes speichern. Cookies können ferner zu
                unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der
                Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten
                sowie der Erstellung von Analysen der Besucherströme.{" "}
              </p>
              <p>
                <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies
                im Einklang mit den gesetzlichen Vorschriften ein. Daher holen
                wir von den Nutzern eine vorhergehende Einwilligung ein, außer
                wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist
                insbesondere nicht notwendig, wenn das Speichern und das
                Auslesen der Informationen, also auch von Cookies, unbedingt
                erforderlich sind, um dem den Nutzern einen von ihnen
                ausdrücklich gewünschten Telemediendienst (also unser
                Onlineangebot) zur Verfügung zu stellen. Die widerrufliche
                Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
                und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
              </p>
              <p>
                <strong>
                  Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:{" "}
                </strong>
                Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die
                personenbezogenen Daten der Nutzer mit Hilfe von Cookies
                verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung
                bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage
                der Verarbeitung Ihrer Daten die erklärte Einwilligung.
                Andernfalls werden die mithilfe von Cookies verarbeiteten Daten
                auf Grundlage unserer berechtigten Interessen (z.B. an einem
                betriebswirtschaftlichen Betrieb unseres Onlineangebotes und
                Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im
                Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt,
                wenn der Einsatz von Cookies erforderlich ist, um unsere
                vertraglichen Verpflichtungen zu erfüllen. Zu welchen Zwecken
                die Cookies von uns verarbeitet werden, darüber klären wir im
                Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
                Einwilligungs- und Verarbeitungsprozessen auf.
              </p>
              <p>
                <strong>Speicherdauer: </strong>Im Hinblick auf die
                Speicherdauer werden die folgenden Arten von Cookies
                unterschieden:
              </p>
              <p>
                <ul>
                  <li>
                    <strong>
                      Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
                    </strong>{" "}
                    Temporäre Cookies werden spätestens gelöscht, nachdem ein
                    Nutzer ein Online-Angebot verlassen und sein Endgerät (z.B.
                    Browser oder mobile Applikation) geschlossen hat.
                  </li>
                  <li>
                    <strong>Permanente Cookies:</strong> Permanente Cookies
                    bleiben auch nach dem Schließen des Endgerätes gespeichert.
                    So können beispielsweise der Login-Status gespeichert oder
                    bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer
                    eine Website erneut besucht. Ebenso können die mit Hilfe von
                    Cookies erhobenen Daten der Nutzer zur Reichweitenmessung
                    verwendet werden. Sofern wir Nutzern keine expliziten
                    Angaben zur Art und Speicherdauer von Cookies mitteilen (z.
                    B. im Rahmen der Einholung der Einwilligung), sollten Nutzer
                    davon ausgehen, dass Cookies permanent sind und die
                    Speicherdauer bis zu zwei Jahre betragen kann.
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):{" "}
                </strong>
                Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
                Widerrufen und zudem einen Widerspruch gegen die Verarbeitung
                entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen
                (weitere Hinweise zum Widerspruch erfolgen im Rahmen dieser
                Datenschutzerklärung). Nutzer können Ihren Widerspruch auch
                mittels der Einstellungen Ihres Browsers erklären.
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>
                      Verarbeitung von Cookie-Daten auf Grundlage einer
                      Einwilligung:{" "}
                    </strong>
                    Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management
                    ein, in dessen Rahmen die Einwilligungen der Nutzer in den
                    Einsatz von Cookies, bzw. der im Rahmen des
                    Cookie-Einwilligungs-Management-Verfahrens genannten
                    Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
                    verwaltet und widerrufen werden können. Hierbei wird die
                    Einwilligungserklärung gespeichert, um deren Abfrage nicht
                    erneut wiederholen zu müssen und die Einwilligung
                    entsprechend der gesetzlichen Verpflichtung nachweisen zu
                    können. Die Speicherung kann serverseitig und/oder in einem
                    Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe
                    vergleichbarer Technologien) erfolgen, um die Einwilligung
                    einem Nutzer, bzw. dessen Gerät zuordnen zu können.
                    Vorbehaltlich individueller Angaben zu den Anbietern von
                    Cookie-Management-Diensten, gelten die folgenden Hinweise:
                    Die Dauer der Speicherung der Einwilligung kann bis zu zwei
                    Jahren betragen. Hierbei wird ein pseudonymer
                    Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
                    Einwilligung, Angaben zur Reichweite der Einwilligung (z. B.
                    welche Kategorien von Cookies und/oder Diensteanbieter)
                    sowie dem Browser, System und verwendeten Endgerät
                    gespeichert.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m225">
                Bereitstellung des Onlineangebotes und Webhosting
              </Heading2>
              <p>
                Um unser Onlineangebot sicher und effizient bereitstellen zu
                können, nehmen wir die Leistungen von einem oder mehreren
                Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von
                ihnen verwalteten Servern) das Onlineangebot abgerufen werden
                kann. Zu diesen Zwecken können wir Infrastruktur- und
                Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
                Datenbankdienste sowie Sicherheitsleistungen und technische
                Wartungsleistungen in Anspruch nehmen.
              </p>
              <p>
                Zu den im Rahmen der Bereitstellung des Hostingangebotes
                verarbeiteten Daten können alle die Nutzer unseres
                Onlineangebotes betreffenden Angaben gehören, die im Rahmen der
                Nutzung und der Kommunikation anfallen. Hierzu gehören
                regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von
                Onlineangeboten an Browser ausliefern zu können, und alle
                innerhalb unseres Onlineangebotes oder von Webseiten getätigten
                Eingaben.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
                    Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte
                    Webseiten, Interesse an Inhalten, Zugriffszeiten);
                    Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                    IP-Adressen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B.
                    Webseitenbesucher, Nutzer von Onlinediensten).
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Bereitstellung
                    unseres Onlineangebotes und Nutzerfreundlichkeit.
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                    (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>E-Mail-Versand und -Hosting: </strong>Die von uns in
                    Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls
                    den Versand, den Empfang sowie die Speicherung von E-Mails.
                    Zu diesen Zwecken werden die Adressen der Empfänger sowie
                    Absender als auch weitere Informationen betreffend den
                    E-Mailversand (z.B. die beteiligten Provider) sowie die
                    Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten
                    Daten können ferner zu Zwecken der Erkennung von SPAM
                    verarbeitet werden. Wir bitten darum, zu beachten, dass
                    E-Mails im Internet grundsätzlich nicht verschlüsselt
                    versendet werden. Im Regelfall werden E-Mails zwar auf dem
                    Transportweg verschlüsselt, aber (sofern kein sogenanntes
                    Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird)
                    nicht auf den Servern, von denen sie abgesendet und
                    empfangen werden. Wir können daher für den Übertragungsweg
                    der E-Mails zwischen dem Absender und dem Empfang auf
                    unserem Server keine Verantwortung übernehmen.
                  </li>
                  <li>
                    <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>
                    Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu
                    jedem Zugriff auf den Server (sogenannte Serverlogfiles). Zu
                    den Serverlogfiles können die Adresse und Name der
                    abgerufenen Webseiten und Dateien, Datum und Uhrzeit des
                    Abrufs, übertragene Datenmengen, Meldung über erfolgreichen
                    Abruf, Browsertyp nebst Version, das Betriebssystem des
                    Nutzers, Referrer URL (die zuvor besuchte Seite) und im
                    Regelfall IP-Adressen und der anfragende Provider gehören.
                    Die Serverlogfiles können zum einen zu Zwecken der
                    Sicherheit eingesetzt werden, z.B., um eine Überlastung der
                    Server zu vermeiden (insbesondere im Fall von
                    missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und
                    zum anderen, um die Auslastung der Server und ihre
                    Stabilität sicherzustellen;{" "}
                    <strong>Löschung von Daten:</strong> Logfile-Informationen
                    werden für die Dauer von maximal 30 Tagen gespeichert und
                    danach gelöscht oder anonymisiert. Daten, deren weitere
                    Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
                    endgültigen Klärung des jeweiligen Vorfalls von der Löschung
                    ausgenommen.
                  </li>
                  <li>
                    <strong>Content-Delivery-Network: </strong>Wir setzen ein
                    "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein
                    Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes,
                    insbesondere große Mediendateien, wie Grafiken oder
                    Programm-Skripte, mit Hilfe regional verteilter und über das
                    Internet verbundener Server schneller und sicherer
                    ausgeliefert werden können.
                  </li>
                  <li>
                    <strong>Amazon Web Services (AWS): </strong>Leistungen auf
                    dem Gebiet der Bereitstellung von informationstechnischer
                    Infrastruktur und verbundenen Dienstleistungen (z.B.
                    Speicherplatz und/oder Rechenkapazitäten);{" "}
                    <strong>Dienstanbieter:</strong> Amazon Web Services, Inc.,
                    410 Terry Avenue North, Seattle WA 98109, USA;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://aws.amazon.com/de/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://aws.amazon.com/de/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://aws.amazon.com/de/privacy/?nc1=f_pr"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://aws.amazon.com/de/privacy/?nc1=f_pr
                    </a>
                    ;{" "}
                    <strong>
                      Standardvertragsklauseln (Gewährleistung Datenschutzniveau
                      bei Verarbeitung in Drittländern):
                    </strong>{" "}
                    <a
                      href="https://aws.amazon.com/de/service-terms/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://aws.amazon.com/de/service-terms/
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
                    <a
                      href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf
                    </a>
                    .
                  </li>
                  <li>
                    <strong>DomainFactory: </strong>Leistungen auf dem Gebiet
                    der Bereitstellung von informationstechnischer Infrastruktur
                    und verbundenen Dienstleistungen (z.B. Speicherplatz
                    und/oder Rechenkapazitäten);{" "}
                    <strong>Dienstanbieter:</strong> domainfactory GmbH,
                    Oskar-Messter-Str. 33, 85737 Ismaning, Deutschland;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://www.df.eu"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.df.eu
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://www.df.eu/de/datenschutz"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.df.eu/de/datenschutz
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
                    <a
                      href="https://www.df.eu/de/support/formulare/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.df.eu/de/support/formulare/
                    </a>
                    .
                  </li>
                  <li>
                    <strong>STRATO: </strong>Leistungen auf dem Gebiet der
                    Bereitstellung von informationstechnischer Infrastruktur und
                    verbundenen Dienstleistungen (z.B. Speicherplatz und/oder
                    Rechenkapazitäten); <strong>Dienstanbieter:</strong> STRATO
                    AG, Pascalstraße 10,10587 Berlin, Deutschland;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://www.strato.de"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.strato.de
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://www.strato.de/datenschutz"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.strato.de/datenschutz
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong> mit
                    Anbieter abgeschlossen.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m104">Blogs und Publikationsmedien</Heading2>
              <p>
                Wir nutzen Blogs oder vergleichbare Mittel der
                Onlinekommunikation und Publikation (nachfolgend
                "Publikationsmedium"). Die Daten der Leser werden für die Zwecke
                des Publikationsmediums nur insoweit verarbeitet, als es für
                dessen Darstellung und die Kommunikation zwischen Autoren und
                Lesern oder aus Gründen der Sicherheit erforderlich ist. Im
                Übrigen verweisen wir auf die Informationen zur Verarbeitung der
                Besucher unseres Publikationsmediums im Rahmen dieser
                Datenschutzhinweise.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten
                    (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail,
                    Telefonnummern); Inhaltsdaten (z.B. Eingaben in
                    Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
                    Interesse an Inhalten, Zugriffszeiten);
                    Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                    IP-Adressen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B.
                    Webseitenbesucher, Nutzer von Onlinediensten).
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Erbringung
                    vertraglicher Leistungen und Kundenservice; Feedback (z.B.
                    Sammeln von Feedback via Online-Formular);
                    Sicherheitsmaßnahmen; Verwaltung und Beantwortung von
                    Anfragen.
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                    vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO);
                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO); Schutz
                    lebenswichtiger Interessen (Art. 6 Abs. 1 S. 1 lit. d.
                    DSGVO).
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Kommentare und Beiträge: </strong>Wenn Nutzer
                    Kommentare oder sonstige Beiträge hinterlassen, können ihre
                    IP-Adressen auf Grundlage unserer berechtigten Interessen
                    gespeichert werden. Das erfolgt zu unserer Sicherheit, falls
                    jemand in Kommentaren und Beiträgen widerrechtliche Inhalte
                    hinterlässt (Beleidigungen, verbotene politische Propaganda
                    etc.). In diesem Fall können wir selbst für den Kommentar
                    oder Beitrag belangt werden und sind daher an der Identität
                    des Verfassers interessiert. Des Weiteren behalten wir uns
                    vor, auf Grundlage unserer berechtigten Interessen die
                    Angaben der Nutzer zwecks Spamerkennung zu verarbeiten. Auf
                    derselben Rechtsgrundlage behalten wir uns vor, im Fall von
                    Umfragen die IP-Adressen der Nutzer für deren Dauer zu
                    speichern und Cookies zu verwenden, um Mehrfachabstimmungen
                    zu vermeiden. Die im Rahmen der Kommentare und Beiträge
                    mitgeteilten Informationen zur Person, etwaige Kontakt-
                    sowie Webseiteninformationen als auch die inhaltlichen
                    Angaben werden von uns bis zum Widerspruch der Nutzer
                    dauerhaft gespeichert.
                  </li>
                  <li>
                    <strong>DISQUS-Kommentarfunktion: </strong>Wir setzen auf
                    Grundlage unserer berechtigten Interessen an einer
                    effizienten, sicheren und nutzerfreundlichen
                    Kommentarverwaltung den Kommentardienst DISQUS ein. Zur
                    Nutzung der DISQUS Kommentarfunktion können Nutzer sich über
                    ein eigenes DISQUS-Nutzer-Konto oder ein bestehendes
                    Social-Media-Konto (z.B. OpenID, Facebook, Twitter oder
                    Google) anmelden. Die Anmeldedaten der Nutzer werden durch
                    DISQUS von den Plattformen bezogen. Es ist ebenfalls
                    möglich, die DISQUS-Kommentarfunktion als Gast, ohne
                    Erstellung oder Verwendung eines Nutzerkontos bei DISQUS
                    oder einem der angegebenen Social-Media-Anbieter, zu nutzen.
                    Wir betten lediglich DISQUS mit seinen Funktionen in unsere
                    Webseite ein, wobei wir auf die Kommentare der Nutzer
                    Einfluss nehmen können. Die Nutzer treten jedoch in eine
                    unmittelbare Vertragsbeziehung mit DISQUS ein, in deren
                    Rahmen DISQUS die Kommentare der Nutzer verarbeitet und ein
                    Ansprechpartner für eine etwaige Löschung der Daten der
                    Nutzer ist. Wir verweisen auf die Datenschutzerklärung von
                    DISQUS und weisen die Nutzer ebenfalls darauf hin, dass sie
                    davon ausgehen können, dass DISQUS neben dem Kommentarinhalt
                    auch deren IP-Adresse und den Zeitpunkt des Kommentars
                    speichert. Ebenfalls können Cookies auf den Rechnern der
                    Nutzer gespeichert und zur Darstellung von Werbung genutzt
                    werden; <strong>Dienstanbieter:</strong> DISQUS, Inc., 301
                    Howard St, Floor 3 San Francisco, California- 94105, USA;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://disqus.com/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://disqus.com/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://help.disqus.com/terms-and-policies/disqus-privacy-policy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://help.disqus.com/terms-and-policies/disqus-privacy-policy
                    </a>
                    ; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
                    <a
                      href="https://disqus.com/data-sharing-settings"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://disqus.com/data-sharing-settings
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m182">Kontakt- und Anfragenverwaltung</Heading2>
              <p>
                Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular,
                E-Mail, Telefon oder via soziale Medien) sowie im Rahmen
                bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben
                der anfragenden Personen verarbeitet soweit dies zur
                Beantwortung der Kontaktanfragen und etwaiger angefragter
                Maßnahmen erforderlich ist.
              </p>
              <p>
                Die Beantwortung der Kontaktanfragen sowie die Verwaltung von
                Kontakt- und Anfragedaten im Rahmen von vertraglichen oder
                vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
                vertraglichen Pflichten oder zur Beantwortung von
                (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der
                berechtigten Interessen an der Beantwortung der Anfragen und
                Pflege von Nutzer- bzw. Geschäftsbeziehungen.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten
                    (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail,
                    Telefonnummern); Inhaltsdaten (z.B. Eingaben in
                    Onlineformularen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Kommunikationspartner.
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen
                    und Kommunikation; Erbringung vertraglicher Leistungen und
                    Kundenservice.
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                    vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO);
                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO);
                    Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO).
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Kontaktformular: </strong>Wenn Nutzer über unser
                    Kontaktformular, E-Mail oder andere Kommunikationswege mit
                    uns in Kontakt treten, verarbeiten wir die uns in diesem
                    Zusammenhang mitgeteilten Daten zur Bearbeitung des
                    mitgeteilten Anliegens. Zu diesem Zweck verarbeiten wir
                    personenbezogene Daten im Rahmen vorvertraglicher und
                    vertraglicher Geschäftsbeziehungen, soweit dies zu deren
                    Erfüllung erforderlich ist und im Übrigen auf Grundlage
                    unserer berechtigten Interessen sowie der Interessen der
                    Kommunikationspartner an der Beantwortung der Anliegen und
                    unserer gesetzlichen Aufbewahrungspflichten.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m263">
                Webanalyse, Monitoring und Optimierung
              </Heading2>
              <p>
                Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient
                der Auswertung der Besucherströme unseres Onlineangebotes und
                kann Verhalten, Interessen oder demographische Informationen zu
                den Besuchern, wie z.B. das Alter oder das Geschlecht, als
                pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse
                können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot
                oder dessen Funktionen oder Inhalte am häufigsten genutzt werden
                oder zur Wiederverwendung einladen. Ebenso können wir
                nachvollziehen, welche Bereiche der Optimierung bedürfen.{" "}
              </p>
              <p>
                Neben der Webanalyse können wir auch Testverfahren einsetzen, um
                z.B. unterschiedliche Versionen unseres Onlineangebotes oder
                seiner Bestandteile zu testen und optimieren.
              </p>
              <p>
                Sofern nachfolgend nicht anders angegeben, können zu diesen
                Zwecken Profile, d.h. zu einem Nutzungsvorgang zusammengefasste
                Daten angelegt und Informationen in einem Browser, bzw. in einem
                Endgerät gespeichert und aus diesem ausgelesen werden. Zu den
                erhobenen Angaben gehören insbesondere besuchte Webseiten und
                dort genutzte Elemente sowie technische Angaben, wie der
                verwendete Browser, das verwendete Computersystem sowie Angaben
                zu Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer
                Standortdaten uns gegenüber oder gegenüber den Anbietern der von
                uns eingesetzten Dienste einverstanden erklärt haben, können
                auch Standortdaten verarbeitet werden.
              </p>
              <p>
                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert.
                Jedoch nutzen wir ein IP-Masking-Verfahren (d.h.,
                Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der
                Nutzer. Generell werden die im Rahmen von Webanalyse,
                A/B-Testings und Optimierung keine Klardaten der Nutzer (wie
                z.B. E-Mail-Adressen oder Namen) gespeichert, sondern
                Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten
                Software kennen nicht die tatsächliche Identität der Nutzer,
                sondern nur den für Zwecke der jeweiligen Verfahren in deren
                Profilen gespeicherten Angaben.
              </p>
              <p>
                <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
                Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
                bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                Einwilligung. Ansonsten werden die Daten der Nutzer auf
                Grundlage unserer berechtigten Interessen (d.h. Interesse an
                effizienten, wirtschaftlichen und empfängerfreundlichen
                Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie
                auch auf die Informationen zur Verwendung von Cookies in dieser
                Datenschutzerklärung hinweisen.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten
                    (z.B. besuchte Webseiten, Interesse an Inhalten,
                    Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
                    Geräte-Informationen, IP-Adressen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B.
                    Webseitenbesucher, Nutzer von Onlinediensten).
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung
                    (z.B. Zugriffsstatistiken, Erkennung wiederkehrender
                    Besucher); Profile mit nutzerbezogenen Informationen
                    (Erstellen von Nutzerprofilen).
                  </li>
                  <li>
                    <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
                    (Pseudonymisierung der IP-Adresse).
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs.
                    1 S. 1 lit. a. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1
                    S. 1 lit. f. DSGVO).
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Google Optimize: </strong>Verwendung von
                    Google-Analytics-Daten zu Zwecken der Verbesserung von
                    Bereichen unseres Onlineangebotes und einer verbesserten
                    Ausrichtung unserer Marketingmaßnahmen an potentiellen
                    Interessen der Nutzer; <strong>Dienstanbieter:</strong>{" "}
                    Google Ireland Limited, Gordon House, Barrow Street, Dublin
                    4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre
                    Parkway, Mountain View, CA 94043, USA;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://optimize.google.com"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://optimize.google.com
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    ; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
                    Opt-Out-Plugin:{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout?hl=de"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
                    <a
                      href="https://adssettings.google.com/authenticated"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://adssettings.google.com/authenticated
                    </a>
                    ; <strong>Weitere Informationen:</strong> Arten der
                    Verarbeitung sowie der verarbeiteten Daten:{" "}
                    <a
                      href="https://privacy.google.com/businesses/adsservices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://privacy.google.com/businesses/adsservices
                    </a>
                    ; Datenverarbeitungsbedingungen für Google Werbeprodukte und
                    Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms
                    </a>
                    .
                  </li>
                  <li>
                    <strong>Google Analytics: </strong>Webanalyse,
                    Reichweitenmessung sowie Messung von Nutzerströmen;{" "}
                    <strong>Dienstanbieter:</strong> Google Ireland Limited,
                    Gordon House, Barrow Street, Dublin 4, Irland,
                    Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                    Mountain View, CA 94043, USA; <strong>Website:</strong>{" "}
                    <a
                      href="https://marketingplatform.google.com/intl/de/about/analytics/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://marketingplatform.google.com/intl/de/about/analytics/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    ; <strong>Weitere Informationen:</strong> Arten der
                    Verarbeitung sowie der verarbeiteten Daten:{" "}
                    <a
                      href="https://privacy.google.com/businesses/adsservices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://privacy.google.com/businesses/adsservices
                    </a>
                    ; Datenverarbeitungsbedingungen für Google Werbeprodukte und
                    Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms
                    </a>
                    .
                  </li>
                  <li>
                    <strong>Google Tag Manager: </strong>Google Tag Manager ist
                    eine Lösung, mit der wir sog. Website-Tags über eine
                    Oberfläche verwalten und so andere Dienste in unser
                    Onlineangebot einbinden können (hierzu wird auf weitere
                    Angaben in dieser Datenschutzerklärung verwiesen). Mit dem
                    Tag Manager selbst (welches die Tags implementiert) werden
                    daher z. B. noch keine Profile der Nutzer erstellt oder
                    Cookies gespeichert. Google erfährt lediglich die IP-Adresse
                    des Nutzers, was notwendig ist, um den Google Tag Manager
                    auszuführen; <strong>Dienstanbieter:</strong> Google Ireland
                    Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                    Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                    Mountain View, CA 94043, USA; <strong>Website:</strong>{" "}
                    <a
                      href="https://marketingplatform.google.com"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://marketingplatform.google.com
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>
                    <a
                      href="https://business.safety.google/adsprocessorterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms
                    </a>
                    ; <strong>Weitere Informationen:</strong> Arten der
                    Verarbeitung sowie der verarbeiteten Daten:{" "}
                    <a
                      href="https://privacy.google.com/businesses/adsservices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://privacy.google.com/businesses/adsservices
                    </a>
                    ; Datenverarbeitungsbedingungen für Google Werbeprodukte und
                    Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m264">Onlinemarketing</Heading2>
              <p>
                Wir verarbeiten personenbezogene Daten zu Zwecken des
                Onlinemarketings, worunter insbesondere die Vermarktung von
                Werbeflächen oder Darstellung von werbenden und sonstigen
                Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand
                potentieller Interessen der Nutzer sowie die Messung ihrer
                Effektivität fallen kann.{" "}
              </p>
              <p>
                Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und
                in einer Datei (sogenannte "Cookie") gespeichert oder ähnliche
                Verfahren genutzt, mittels derer die für die Darstellung der
                vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
                werden. Zu diesen Angaben können z.B. betrachtete Inhalte,
                besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
                Kommunikationspartner und technische Angaben, wie der verwendete
                Browser, das verwendete Computersystem sowie Angaben zu
                Nutzungszeiten und genutzten Funktionen gehören. Sofern Nutzer
                in die Erhebung ihrer Standortdaten eingewilligt haben, können
                auch diese verarbeitet werden.
              </p>
              <p>
                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert.
                Jedoch nutzen wir zur Verfügung stehende IP-Masking-Verfahren
                (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum
                Schutz der Nutzer. Generell werden im Rahmen des
                Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
                E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme.
                D.h., wir als auch die Anbieter der Onlinemarketingverfahren
                kennen nicht die tatsächliche Identität der Nutzer, sondern nur
                die in deren Profilen gespeicherten Angaben.
              </p>
              <p>
                Die Angaben in den Profilen werden im Regelfall in den Cookies
                oder mittels ähnlicher Verfahren gespeichert. Diese Cookies
                können später generell auch auf anderen Webseiten die dasselbe
                Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken
                der Darstellung von Inhalten analysiert als auch mit weiteren
                Daten ergänzt und auf dem Server des
                Onlinemarketingverfahrensanbieters gespeichert werden.
              </p>
              <p>
                Ausnahmsweise können Klardaten den Profilen zugeordnet werden.
                Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
                Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen
                und das Netzwerk die Profile der Nutzer mit den vorgenannten
                Angaben verbindet. Wir bitten darum, zu beachten, dass Nutzer
                mit den Anbietern zusätzliche Abreden, z.B. durch Einwilligung
                im Rahmen der Registrierung, treffen können.
              </p>
              <p>
                Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
                Informationen über den Erfolg unserer Werbeanzeigen. Jedoch
                können wir im Rahmen sogenannter Konversionsmessungen prüfen,
                welche unserer Onlinemarketingverfahren zu einer sogenannten
                Konversion geführt haben, d.h. z.B., zu einem Vertragsschluss
                mit uns. Die Konversionsmessung wird alleine zur Analyse des
                Erfolgs unserer Marketingmaßnahmen verwendet.
              </p>
              <p>
                Solange nicht anders angegeben, bitten wir Sie davon auszugehen,
                dass verwendete Cookies für einen Zeitraum von zwei Jahren
                gespeichert werden.
              </p>
              <p>
                <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
                Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
                bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                Einwilligung. Ansonsten werden die Daten der Nutzer auf
                Grundlage unserer berechtigten Interessen (d.h. Interesse an
                effizienten, wirtschaftlichen und empfängerfreundlichen
                Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie
                auch auf die Informationen zur Verwendung von Cookies in dieser
                Datenschutzerklärung hinweisen.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten
                    (z.B. besuchte Webseiten, Interesse an Inhalten,
                    Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
                    Geräte-Informationen, IP-Adressen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B.
                    Webseitenbesucher, Nutzer von Onlinediensten).
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Remarketing;
                    Zielgruppenbildung (Bestimmung von für Marketingzwecke
                    relevanten Zielgruppen oder sonstige Ausgabe von Inhalten);
                    Marketing; Profile mit nutzerbezogenen Informationen
                    (Erstellen von Nutzerprofilen); Konversionsmessung (Messung
                    der Effektivität von Marketingmaßnahmen).
                  </li>
                  <li>
                    <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
                    (Pseudonymisierung der IP-Adresse).
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs.
                    1 S. 1 lit. a. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1
                    S. 1 lit. f. DSGVO).
                  </li>
                  <li>
                    <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Wir
                    verweisen auf die Datenschutzhinweise der jeweiligen
                    Anbieter und die zu den Anbietern angegebenen
                    Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine
                    explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum
                    einen die Möglichkeit, dass Sie Cookies in den Einstellungen
                    Ihres Browsers abschalten. Hierdurch können jedoch
                    Funktionen unseres Onlineangebotes eingeschränkt werden. Wir
                    empfehlen daher zusätzlich die folgenden
                    Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige
                    Gebiete gerichtet angeboten werden:
                    <br />
                    Europa:{" "}
                    <a
                      href="https://www.youronlinechoices.eu"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.youronlinechoices.eu
                    </a>
                    .<br />
                    Kanada:{" "}
                    <a
                      href="https://www.youradchoices.ca/choices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.youradchoices.ca/choices
                    </a>
                    .<br />
                    USA:{" "}
                    <a
                      href="https://www.aboutads.info/choices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.aboutads.info/choices
                    </a>
                    .<br />
                    Gebietsübergreifend:{" "}
                    <a
                      href="https://optout.aboutads.info"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://optout.aboutads.info
                    </a>
                    .
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Google Ad Manager: </strong>Wir nutzen die "Google
                    Marketing Platform" (und Dienste wie z.B. "Google Ad
                    Manager"), um Anzeigen im Google-Werbe-Netzwerk zu
                    platzieren (z.B., in Suchergebnissen, in Videos, auf
                    Webseiten, etc.). Die Google Marketing Platform zeichnet
                    sich dadurch aus, dass Anzeigen in Echtzeit anhand
                    mutmaßlicher Interessen der Nutzer angezeigt werden. Dies
                    erlaubt uns Anzeigen für und innerhalb unseres
                    Onlineangebotes gezielter anzuzeigen, um Nutzern nur
                    Anzeigen zu präsentieren, die potentiell deren Interessen
                    entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte
                    angezeigt werden, für die er sich auf anderen
                    Onlineangeboten interessiert hat, spricht man hierbei vom
                    "Remarketing"; <strong>Dienstanbieter:</strong> Google
                    Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                    Ireland, parent company: Google LLC, 1600 Amphitheatre
                    Parkway, Mountain View, CA 94043, USA;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://marketingplatform.google.com"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://marketingplatform.google.com
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    ; <strong>Weitere Informationen:</strong> Arten der
                    Verarbeitung sowie der verarbeiteten Daten:{" "}
                    <a
                      href="https://privacy.google.com/businesses/adsservices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://privacy.google.com/businesses/adsservices
                    </a>
                    ; Datenverarbeitungsbedingungen für Google Werbeprodukte:
                    Informationen zu den Diensten Datenverarbeitungsbedingungen
                    zwischen Verantwortlichen und Standardvertragsklauseln für
                    Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adscontrollerterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adscontrollerterms
                    </a>
                    ; sofern Google als Auftragsverarbeiter fungiert,
                    Datenverarbeitungsbedingungen für Google Werbeprodukte und
                    Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms
                    </a>
                    .
                  </li>
                  <li>
                    <strong>Google Ads und Konversionsmessung: </strong>Wir
                    nutzen das Onlinemarketingverfahren "Google Ads", um
                    Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in
                    Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie
                    Nutzern angezeigt werden, die ein mutmaßliches Interesse an
                    den Anzeigen haben (sog. "Konversion"). Ferner messen wir
                    die Konversion der Anzeigen. Wir erfahren jedoch nur die
                    anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige
                    geklickt haben und zu einer mit einem sog
                    "Conversion-Tracking-Tag" versehenen Seite weitergeleitet
                    wurden. Wir selbst erhalten jedoch keine Informationen, mit
                    denen sich Nutzer identifizieren lassen;{" "}
                    <strong>Dienstanbieter:</strong> Google Ireland Limited,
                    Gordon House, Barrow Street, Dublin 4, Ireland,
                    Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                    Mountain View, CA 94043, USA; <strong>Website:</strong>{" "}
                    <a
                      href="https://marketingplatform.google.com"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://marketingplatform.google.com
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    ; <strong>Weitere Informationen:</strong> Arten der
                    Verarbeitung sowie der verarbeiteten Daten:{" "}
                    <a
                      href="https://privacy.google.com/businesses/adsservices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://privacy.google.com/businesses/adsservices
                    </a>
                    ; Datenverarbeitungsbedingungen für Google Werbeprodukte:
                    Informationen zu den Diensten Datenverarbeitungsbedingungen
                    zwischen Verantwortlichen und Standardvertragsklauseln für
                    Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adscontrollerterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adscontrollerterms
                    </a>
                    .
                  </li>
                  <li>
                    <strong>Zielgruppenbildung mit Google Analytics: </strong>
                    Wir setzen Google Analytics ein, um die durch innerhalb von
                    Werbediensten Googles und seiner Partner geschalteten
                    Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein
                    Interesse an unserem Onlineangebot gezeigt haben oder die
                    bestimmte Merkmale (z.B. Interessen an bestimmten Themen
                    oder Produkten, die anhand der besuchten Webseiten bestimmt
                    werden) aufweisen, die wir an Google übermitteln (sog.
                    "Remarketing-", bzw. "Google-Analytics-Audiences"). Mit
                    Hilfe der Remarketing Audiences möchten wir auch
                    sicherstellen, dass unsere Anzeigen dem potentiellen
                    Interesse der Nutzer entsprechen;{" "}
                    <strong>Dienstanbieter:</strong> Google Ireland Limited,
                    Gordon House, Barrow Street, Dublin 4, Ireland, parent
                    company: Google LLC, 1600 Amphitheatre Parkway, Mountain
                    View, CA 94043, USA; <strong>Website:</strong>{" "}
                    <a
                      href="https://marketingplatform.google.com"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://marketingplatform.google.com
                    </a>
                    ; <strong>Rechtsgrundlagen:</strong>{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms/
                    </a>
                    ; <strong>Weitere Informationen:</strong> Arten der
                    Verarbeitung sowie der verarbeiteten Daten:{" "}
                    <a
                      href="https://privacy.google.com/businesses/adsservices"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://privacy.google.com/businesses/adsservices
                    </a>
                    ; Datenverarbeitungsbedingungen für Google Werbeprodukte und
                    Standardvertragsklauseln für Drittlandtransfers von Daten:{" "}
                    <a
                      href="https://business.safety.google/adsprocessorterms"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://business.safety.google/adsprocessorterms
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m135">
                Affiliate-Programme und Affiliate-Links
              </Heading2>
              <p>
                In unser Onlineangebot binden wir sogenannte Affiliate-Links
                oder andere Verweise (zu denen z.B. Suchmasken, Widgets oder
                Rabatt-Codes gehören können) auf die Angebote und Leistungen von
                Drittanbietern ein (zusammenfassend bezeichnet als
                "Affiliate-Links"). Wenn Nutzer den Affiliate-Links folgen, bzw.
                anschließend die Angebote wahrnehmen, können wir von diesen
                Drittanbietern eine Provision oder sonstige Vorteile erhalten
                (zusammenfassend bezeichnet als "Provision").
              </p>
              <p>
                Um nachverfolgen zu können, ob die Nutzer die Angebote eines von
                uns eingesetzten Affiliate-Links wahrgenommen haben, ist es
                notwendig, dass die jeweiligen Drittanbieter erfahren, dass die
                Nutzer einem innerhalb unseres Onlineangebotes eingesetzten
                Affiliate-Link gefolgt sind. Die Zuordnung der Affiliate-Links
                zu den jeweiligen Geschäftsabschlüssen oder zu sonstigen
                Aktionen (z.B. Käufen) dient alleine dem Zweck der
                Provisionsabrechnung und wird aufgehoben, sobald sie für den
                Zweck nicht mehr erforderlich ist.
              </p>
              <p>
                Für die Zwecke der vorgenannten Zuordnung der Affiliate-Links
                können die Affiliate-Links um bestimmte Werte ergänzt werden,
                die ein Bestandteil des Links sind oder anderweitig, z.B. in
                einem Cookie, gespeichert werden können. Zu den Werten können
                insbesondere die Ausgangswebseite (Referrer), der Zeitpunkt,
                eine Online-Kennung der Betreiber der Webseite, auf der sich der
                Affiliate-Link befand, eine Online-Kennung des jeweiligen
                Angebotes, die Art des verwendeten Links, die Art des Angebotes
                und eine Online-Kennung des Nutzers gehören.
              </p>
              <p>
                <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
                Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
                bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer
                (vor)vertraglichen Leistungen sein, sofern der Einsatz der
                Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten
                werden die Daten der Nutzer auf Grundlage unserer berechtigten
                Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
                empfängerfreundlichen Leistungen) verarbeitet. In diesem
                Zusammenhang möchten wir Sie auch auf die Informationen zur
                Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Vertragsdaten
                    (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie);
                    Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                    Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
                    Geräte-Informationen, IP-Adressen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B.
                    Webseitenbesucher, Nutzer von Onlinediensten).
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong>{" "}
                    Affiliate-Nachverfolgung.
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs.
                    1 S. 1 lit. a. DSGVO); Vertragserfüllung und vorvertragliche
                    Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte
                    Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Amazon-Partnerprogramm: </strong>
                    Affiliate-Partnerprogramm (Amazon und das Amazon-Logo sind
                    Warenzeichen von Amazon.com, Inc. oder eines seiner
                    verbundenen Unternehmen); <strong>
                      Dienstanbieter:
                    </strong>{" "}
                    Amazon Europe Core S.à.r.l., die Amazon EU S.à.r.l, die
                    Amazon Services Europe S.à.r.l. und die Amazon Media EU
                    S.à.r.l., alle vier ansässig in 38, avenue John F. Kennedy,
                    L-1855 Luxemburg, sowie Amazon Instant Video Germany GmbH,
                    Domagkstr. 28, 80807 München (zusammen "Amazon Europe"),
                    Mutterunternehmen: Amazon.com, Inc., 2021 Seventh Ave,
                    Seattle, Washington 98121, USA; <strong>Website:</strong>{" "}
                    <a
                      href="https://www.amazon.de"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.amazon.de
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m328">
                Plugins und eingebettete Funktionen sowie Inhalte
              </Heading2>
              <p>
                Wir binden in unser Onlineangebot Funktions- und Inhaltselemente
                ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend
                bezeichnet als "Drittanbieter") bezogen werden. Dabei kann es
                sich zum Beispiel um Grafiken, Videos oder Stadtpläne handeln
                (nachfolgend einheitlich bezeichnet als "Inhalte").
              </p>
              <p>
                Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
                Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
                IP-Adresse die Inhalte nicht an deren Browser senden könnten.
                Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder
                Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
                verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
                Auslieferung der Inhalte verwenden. Drittanbieter können ferner
                sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web
                Beacons" bezeichnet) für statistische oder Marketingzwecke
                verwenden. Durch die "Pixel-Tags" können Informationen, wie der
                Besucherverkehr auf den Seiten dieser Webseite, ausgewertet
                werden. Die pseudonymen Informationen können ferner in Cookies
                auf dem Gerät der Nutzer gespeichert werden und unter anderem
                technische Informationen zum Browser und zum Betriebssystem, zu
                verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben
                zur Nutzung unseres Onlineangebotes enthalten als auch mit
                solchen Informationen aus anderen Quellen verbunden werden.
              </p>
              <p>
                <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
                Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
                bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                Einwilligung. Ansonsten werden die Daten der Nutzer auf
                Grundlage unserer berechtigten Interessen (d.h. Interesse an
                effizienten, wirtschaftlichen und empfängerfreundlichen
                Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie
                auch auf die Informationen zur Verwendung von Cookies in dieser
                Datenschutzerklärung hinweisen.
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten
                    (z.B. besuchte Webseiten, Interesse an Inhalten,
                    Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
                    Geräte-Informationen, IP-Adressen).
                  </li>
                  <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B.
                    Webseitenbesucher, Nutzer von Onlinediensten).
                  </li>
                  <li>
                    <strong>Zwecke der Verarbeitung:</strong> Bereitstellung
                    unseres Onlineangebotes und Nutzerfreundlichkeit; Erbringung
                    vertraglicher Leistungen und Kundenservice.
                  </li>
                  <li>
                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                    (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                  </li>
                </ul>
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <p>
                <ul class="m-elements">
                  <li>
                    <strong>
                      Einbindung von Drittsoftware, Skripten oder Frameworks (z.
                      B. jQuery):{" "}
                    </strong>
                    Wir binden in unser Onlineangebot Software ein, die wir von
                    Servern anderer Anbieter abrufen (z.B.
                    Funktions-Bibliotheken, die wir zwecks Darstellung oder
                    Nutzerfreundlichkeit unseres Onlineangebotes verwenden).
                    Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
                    Nutzer und können diese zu Zwecken der Übermittlung der
                    Software an den Browser der Nutzer sowie zu Zwecken der
                    Sicherheit, als auch zur Auswertung und Optimierung ihres
                    Angebotes verarbeiten. - Wir binden in unser Onlineangebot
                    Software ein, die wir von Servern anderer Anbieter abrufen
                    (z.B. Funktions-Bibliotheken, die wir zwecks Darstellung
                    oder Nutzerfreundlichkeit unseres Onlineangebotes
                    verwenden). Hierbei erheben die jeweiligen Anbieter die
                    IP-Adresse der Nutzer und können diese zu Zwecken der
                    Übermittlung der Software an den Browser der Nutzer sowie zu
                    Zwecken der Sicherheit, als auch zur Auswertung und
                    Optimierung ihres Angebotes verarbeiten.
                  </li>
                  <li>
                    <strong>Font Awesome: </strong>Darstellung von Schriftarten
                    und Symbolen; <strong>Dienstanbieter:</strong> Fonticons,
                    Inc. ,6 Porter Road Apartment 3R, Cambridge, MA 02140, USA;{" "}
                    <strong>Website:</strong>{" "}
                    <a
                      href="https://fontawesome.com/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://fontawesome.com/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://fontawesome.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://fontawesome.com/privacy
                    </a>
                    .
                  </li>
                  <li>
                    <strong>Google Fonts: </strong>Wir binden die Schriftarten
                    ("Google Fonts") des Anbieters Google ein, wobei die Daten
                    der Nutzer allein zu Zwecken der Darstellung der
                    Schriftarten im Browser der Nutzer verwendet werden. Die
                    Einbindung erfolgt auf Grundlage unserer berechtigten
                    Interessen an einer technisch sicheren, wartungsfreien und
                    effizienten Nutzung von Schriftarten, deren einheitlicher
                    Darstellung sowie unter Berücksichtigung möglicher
                    lizenzrechtlicher Restriktionen für deren Einbindung;{" "}
                    <strong>Dienstanbieter:</strong> Google Ireland Limited,
                    Gordon House, Barrow Street, Dublin 4, Irland,
                    Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                    Mountain View, CA 94043, USA; <strong>Website:</strong>{" "}
                    <a
                      href="https://fonts.google.com/"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://fonts.google.com/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      https://policies.google.com/privacy
                    </a>
                    .
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m15">
                Änderung und Aktualisierung der Datenschutzerklärung
              </Heading2>
              <p>
                Wir bitten Sie, sich regelmäßig über den Inhalt unserer
                Datenschutzerklärung zu informieren. Wir passen die
                Datenschutzerklärung an, sobald die Änderungen der von uns
                durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
                informieren Sie, sobald durch die Änderungen eine
                Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
                sonstige individuelle Benachrichtigung erforderlich wird.
              </p>
              <p>
                Sofern wir in dieser Datenschutzerklärung Adressen und
                Kontaktinformationen von Unternehmen und Organisationen angeben,
                bitten wir zu beachten, dass die Adressen sich über die Zeit
                ändern können und bitten die Angaben vor Kontaktaufnahme zu
                prüfen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m10">Rechte der betroffenen Personen</Heading2>
              <p>
                Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte
                zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
              </p>
              <p>
                <ul>
                  <li>
                    <strong>
                      Widerspruchsrecht: Sie haben das Recht, aus Gründen, die
                      sich aus Ihrer besonderen Situation ergeben, jederzeit
                      gegen die Verarbeitung der Sie betreffenden
                      personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
                      lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
                      gilt auch für ein auf diese Bestimmungen gestütztes
                      Profiling. Werden die Sie betreffenden personenbezogenen
                      Daten verarbeitet, um Direktwerbung zu betreiben, haben
                      Sie das Recht, jederzeit Widerspruch gegen die
                      Verarbeitung der Sie betreffenden personenbezogenen Daten
                      zum Zwecke derartiger Werbung einzulegen; dies gilt auch
                      für das Profiling, soweit es mit solcher Direktwerbung in
                      Verbindung steht.
                    </strong>
                  </li>
                  <li>
                    <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie
                    haben das Recht, erteilte Einwilligungen jederzeit zu
                    widerrufen.
                  </li>
                  <li>
                    <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
                    Bestätigung darüber zu verlangen, ob betreffende Daten
                    verarbeitet werden und auf Auskunft über diese Daten sowie
                    auf weitere Informationen und Kopie der Daten entsprechend
                    den gesetzlichen Vorgaben.
                  </li>
                  <li>
                    <strong>Recht auf Berichtigung:</strong> Sie haben
                    entsprechend den gesetzlichen Vorgaben das Recht, die
                    Vervollständigung der Sie betreffenden Daten oder die
                    Berichtigung der Sie betreffenden unrichtigen Daten zu
                    verlangen.
                  </li>
                  <li>
                    <strong>
                      Recht auf Löschung und Einschränkung der Verarbeitung:
                    </strong>{" "}
                    Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht,
                    zu verlangen, dass Sie betreffende Daten unverzüglich
                    gelöscht werden, bzw. alternativ nach Maßgabe der
                    gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
                    der Daten zu verlangen.
                  </li>
                  <li>
                    <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben
                    das Recht, Sie betreffende Daten, die Sie uns bereitgestellt
                    haben, nach Maßgabe der gesetzlichen Vorgaben in einem
                    strukturierten, gängigen und maschinenlesbaren Format zu
                    erhalten oder deren Übermittlung an einen anderen
                    Verantwortlichen zu fordern.
                  </li>
                  <li>
                    <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
                    unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                    gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei
                    einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
                    ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes
                    oder des Orts des mutmaßlichen Verstoßes, wenn Sie der
                    Ansicht sind, dass die Verarbeitung der Sie betreffenden
                    personenbezogenen Daten gegen die Vorgaben der DSGVO
                    verstößt.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="m42">Begriffsdefinitionen</Heading2>
              <p>
                In diesem Abschnitt erhalten Sie eine Übersicht über die in
                dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele
                der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4
                DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich.
                Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
                Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
              </p>
              <p>
                <ul class="glossary">
                  <li>
                    <strong>Affiliate-Nachverfolgung:</strong> Im Rahmen der
                    Affiliate-Nachverfolgung werden Links, mit deren Hilfe die
                    verlinkenden Webseiten Nutzer zu Webseiten mit Produkt- oder
                    sonstigen Angeboten verweisen, protokolliert. Die Betreiber
                    der jeweils verlinkenden Webseiten können eine Provision
                    erhalten, wenn Nutzer diesen sogenannten Affiliate-Links
                    folgen und anschließend die Angebote wahrnehmen (z.B. Waren
                    kaufen oder Dienstleistungen in Anspruch nehmen). Hierzu ist
                    es erforderlich, dass die Anbieter nachverfolgen können, ob
                    Nutzer, die sich für bestimmte Angebote interessieren, diese
                    anschließend auf die Veranlassung der Affiliate-Links
                    wahrnehmen. Daher ist es für die Funktionsfähigkeit von
                    Affiliate-Links erforderlich, dass sie um bestimmte Werte
                    ergänzt werden, die ein Bestandteil des Links werden oder
                    anderweitig, z.B. in einem Cookie, gespeichert werden. Zu
                    den Werten gehören insbesondere die Ausgangswebseite
                    (Referrer), der Zeitpunkt, eine Online-Kennung der Betreiber
                    der Webseite, auf der sich der Affiliate-Link befand, eine
                    Online-Kennung des jeweiligen Angebotes, eine Online-Kennung
                    des Nutzers als auch nachverfolgungsspezifische Werte, wie,
                    z.B. Werbemittel-ID, Partner-ID und Kategorisierungen{" "}
                  </li>
                  <li>
                    <strong>Konversionsmessung:</strong> Die Konversionsmessung
                    (auch als "Besuchsaktionsauswertung" bezeichnet) ist ein
                    Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen
                    festgestellt werden kann. Dazu wird im Regelfall ein Cookie
                    auf den Geräten der Nutzer innerhalb der Webseiten, auf
                    denen die Marketingmaßnahmen erfolgen, gespeichert und dann
                    erneut auf der Zielwebseite abgerufen. Beispielsweise können
                    wir so nachvollziehen, ob die von uns auf anderen Webseiten
                    geschalteten Anzeigen erfolgreich waren.{" "}
                  </li>
                  <li>
                    <strong>Personenbezogene Daten:</strong> "Personenbezogene
                    Daten" sind alle Informationen, die sich auf eine
                    identifizierte oder identifizierbare natürliche Person (im
                    Folgenden "betroffene Person") beziehen; als identifizierbar
                    wird eine natürliche Person angesehen, die direkt oder
                    indirekt, insbesondere mittels Zuordnung zu einer Kennung
                    wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
                    einer Online-Kennung (z.B. Cookie) oder zu einem oder
                    mehreren besonderen Merkmalen identifiziert werden kann, die
                    Ausdruck der physischen, physiologischen, genetischen,
                    psychischen, wirtschaftlichen, kulturellen oder sozialen
                    Identität dieser natürlichen Person sind.{" "}
                  </li>
                  <li>
                    <strong>Profile mit nutzerbezogenen Informationen:</strong>{" "}
                    Die Verarbeitung von "Profilen mit nutzerbezogenen
                    Informationen", bzw. kurz "Profilen" umfasst jede Art der
                    automatisierten Verarbeitung personenbezogener Daten, die
                    darin besteht, dass diese personenbezogenen Daten verwendet
                    werden, um bestimmte persönliche Aspekte, die sich auf eine
                    natürliche Person beziehen (je nach Art der Profilbildung
                    können dazu unterschiedliche Informationen betreffend die
                    Demographie, Verhalten und Interessen, wie z.B. die
                    Interaktion mit Webseiten und deren Inhalten, etc.) zu
                    analysieren, zu bewerten oder, um sie vorherzusagen (z.B.
                    die Interessen an bestimmten Inhalten oder Produkten, das
                    Klickverhalten auf einer Webseite oder den Aufenthaltsort).
                    Zu Zwecken des Profilings werden häufig Cookies und
                    Web-Beacons eingesetzt.{" "}
                  </li>
                  <li>
                    <strong>Reichweitenmessung:</strong> Die Reichweitenmessung
                    (auch als Web Analytics bezeichnet) dient der Auswertung der
                    Besucherströme eines Onlineangebotes und kann das Verhalten
                    oder Interessen der Besucher an bestimmten Informationen,
                    wie z.B. Inhalten von Webseiten, umfassen. Mit Hilfe der
                    Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
                    welcher Zeit Besucher ihre Webseite besuchen und für welche
                    Inhalte sie sich interessieren. Dadurch können sie z.B. die
                    Inhalte der Webseite besser an die Bedürfnisse ihrer
                    Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden
                    häufig pseudonyme Cookies und Web-Beacons eingesetzt, um
                    wiederkehrende Besucher zu erkennen und so genauere Analysen
                    zur Nutzung eines Onlineangebotes zu erhalten.{" "}
                  </li>
                  <li>
                    <strong>Remarketing:</strong> Vom "Remarketing" bzw.
                    "Retargeting" spricht man, wenn z.B. zu Werbezwecken
                    vermerkt wird, für welche Produkte sich ein Nutzer auf einer
                    Webseite interessiert hat, um den Nutzer auf anderen
                    Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu
                    erinnern.{" "}
                  </li>
                  <li>
                    <strong>Verantwortlicher:</strong> Als "Verantwortlicher"
                    wird die natürliche oder juristische Person, Behörde,
                    Einrichtung oder andere Stelle, die allein oder gemeinsam
                    mit anderen über die Zwecke und Mittel der Verarbeitung von
                    personenbezogenen Daten entscheidet, bezeichnet.{" "}
                  </li>
                  <li>
                    <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit
                    oder ohne Hilfe automatisierter Verfahren ausgeführte
                    Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                    personenbezogenen Daten. Der Begriff reicht weit und umfasst
                    praktisch jeden Umgang mit Daten, sei es das Erheben, das
                    Auswerten, das Speichern, das Übermitteln oder das Löschen.{" "}
                  </li>
                  <li>
                    <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung
                    (bzw. "Custom Audiences") spricht man, wenn Zielgruppen für
                    Werbezwecke, z.B. Einblendung von Werbeanzeigen, bestimmt
                    werden. So kann z.B. anhand des Interesses eines Nutzers an
                    bestimmten Produkten oder Themen im Internet
                    geschlussfolgert werden, dass dieser Nutzer sich für
                    Werbeanzeigen für ähnliche Produkte oder den Onlineshop, in
                    dem er die Produkte betrachtet hat, interessiert. Von
                    "Lookalike Audiences" (bzw. ähnlichen Zielgruppen) spricht
                    man wiederum, wenn die als geeignet eingeschätzten Inhalte
                    Nutzern angezeigt werden, deren Profile bzw. Interessen
                    mutmaßlich den Nutzern, zu denen die Profile gebildet
                    wurden, entsprechen. Zu Zwecken der Bildung von Custom
                    Audiences und Lookalike Audiences werden im Regelfall
                    Cookies und Web-Beacons eingesetzt.{" "}
                  </li>
                </ul>
              </p>
              <p class="seal">
                <a
                  href="https://datenschutz-generator.de/"
                  title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
                    alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                    width="250"
                    height="250"
                  />
                </a>
              </p>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default Datenschutz
